@font-face {
  font-family: 'Montserrat Bold';
  src: url('./assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat Semi-Bold';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat Medium Italic';
  src: url('./assets/fonts/Montserrat-MediumItalic.ttf');
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat Light';
  src: url('./assets/fonts/Montserrat-Light.ttf');
}

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
}