@import '~antd/dist/antd.css';
#results {
    margin: 2% 0;
}

.results-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
}

#table-btn-group {

}

#table-btn-group>.ant-select {
    width: 20%;
    margin-bottom: 10px;
    font-family: 'Montserrat Regular';
    font-size: 14px;
}

#table-btn-group>.ant-select:hover, #table-btn-group>.ant-select:focus {
    color: #d83b0b;
}

#table-btn-group>.ant-select:hover>.ant-select-selector, #table-btn-group>.ant-select:focus>.ant-select-selector, #table-btn-group>.ant-select-focused>.ant-select-selector {
    border-color: #d83b0b;
}

.results-table {
    max-width: 40vw;
}

.ball-span > svg {
    transition: 0.9s;
    animation: spin 0.9s ease-in-out;
}

.empty-txt {
    font-weight: bold;
    font-size: 12px;
}

@media only screen and (max-device-width: 1300px) {
    .results-table {
        max-width: 60vw;
    }
}

@media only screen and (max-device-width: 900px) {
    .results-table {
        max-width: 90vw;
    }
}

@media only screen and (max-device-width: 768px) {
    .results-table {
        max-width: 100vw;
    }
}