@import '~antd/dist/antd.css';
#rating {
    margin: 2% 0;
}

.rating-table {
    max-width: 75vw;
    
}

#table-btn-group {
    display: flex;
    align-items: flex-end;
    color: #fa541c;
}

#table-btn-group>.ant-select {
    width: 20%;
    margin-bottom: 10px;
    font-family: 'Montserrat Regular';
    font-size: 14px;
}

.ant-table-highlighted{
    color: #fa541c;

}

#table-highlighted{
    color: #fa541c;

}

#table-btn-group>.ant-select:hover, #table-btn-group>.ant-select:focus {
    color: #fa541c;
}

#table-btn-group>.ant-select:hover>.ant-select-selector, #table-btn-group>.ant-select:focus>.ant-select-selector, #table-btn-group>.ant-select-focused>.ant-select-selector {
    border-color: #fa541c;
}

.ball-span > svg {
    transition: 0.9s;
    animation: spin 0.9s ease-in-out;
}

.empty-txt {
    font-weight: bold;
    font-size: 20px;
}

@media only screen and (max-device-width: 768px) {
    .rating-table {
        max-width: 100vw;
    }
}

.btn {
    width:15vw;
    overflow-wrap: normal;
    /* Clean style */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    color: var(--button-text-color);
    cursor: pointer;
    /* Clean style */
    
    --button-text-color: #ece8e1;
    --button-text-color-hover: var(--button-background-color);
    --border-color: #7D8082;
    --button-background-color: #000000;
    --highlight-color: #ece8e1;
    --button-inner-border-color: transparent;
    --button-bits-color: var(--background-color);
    --button-bits-color-hover: var(--button-background-color);
    
    position: relative;
    padding: 8px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    transition: all .15s ease;
    justify-content: center;
  }
