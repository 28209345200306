/* import this in other css to use ant design */

@import '~antd/dist/antd.css';
/* pc */

/*  need alternative solution for mobile use
    multiple buttons in row won't look good 
*/


#btn-group {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#btn-group>a {
    margin: 0px 10px;
    width: 15%;
}

.btn {
    width:15vw;
    overflow-wrap: normal;
    /* Clean style */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    color: var(--button-text-color);
    cursor: pointer;
    /* Clean style */
    
    --button-text-color: #ece8e1;
    --button-text-color-hover: var(--button-background-color);
    --border-color: #7D8082;
    --button-background-color: #000000;
    --highlight-color: #ece8e1;
    --button-inner-border-color: transparent;
    --button-bits-color: var(--background-color);
    --button-bits-color-hover: var(--button-background-color);
    
    position: relative;
    padding: 8px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    transition: all .15s ease;
    justify-content: center;
  }

  .btn:disabled {
    opacity: 0.5;
    background-color: red;
    cursor: not-allowed;
  }

  .btn::before,
  .btn::after {
    content: '';
    display: block;
    position: absolute;
    right: 0; left: 0;
    height: calc(50% - 5px);
    border: 1px solid var(--border-color);
    transition: all .15s ease;
  }
  
  .btn::before {
    top: 0;
    border-bottom-width: 0;
  }
  
  .btn::after {
    bottom: 0;
    border-top-width: 0;
  }
  
  .btn:active,
  .btn:focus {
    outline: none;
  }
  
  .btn:active::before,
  .btn:active::after {
    right: 3px;
    left: 3px;
  }
  
  .btn:active::before {
    top: 3px;
  }
  
  .btn:active::after {
    bottom: 3px;
  }
  
  .btn__inner { 
    position: relative;
    display: block;
    padding: 0.9vw 1.3vw;
    background-image: url('../img/botoes.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px var(--button-inner-border-color);
    justify-content: center;
  }
  
  .btn__slide {   
    display: block;
    position: absolute;
    top: 0; bottom: -1px; left: -8px;
    width: 0;
    background-color: var(--highlight-color);
    transform: skew(-15deg);
    transition: all .2s ease;
  }
  
  .btn__content {
    position:relative;
    font-family: 'Montserrat Bold';
    font-size: 1.35vw;
    align-self: center;
  }
  
  .btn:hover {
    color: var(--button-text-color-hover);
  }
  
  .btn:hover .btn__slide {
    width: calc(100% + 15px);
  }
  
  .btn:hover .btn__inner::after {
    background-color: var(--button-bits-color-hover);
  }
  
  .btn--light {
    --button-background-color: var(--background-color);
    --button-text-color: var(--highlight-color);
    --button-inner-border-color: var(--highlight-color);
    --button-text-color-hover: #ece8e1;
    --button-bits-color-hover: #ece8e1;
  }

  @media only screen and (max-device-width: 768px) {
    #btn-group {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 0rem;
        display: grid;
        justify-content: center;
        width: 100%;
    }
    .btn {
        max-width: screen;
        padding: 0px;
        width: 50vw;
    }
    .btn__content{
      font-size: 3vw;
    }
    .btn::before,
    .btn::after {
      content: '';
      display: block;
      position: absolute;
      right: -6px; left: -6px;
      height: calc(50% - 5px);
      border: 1px solid var(--border-color);
      transition: all .15s ease;
    }
    
    .btn::before {
      top: -6px;
      border-bottom-width: 0px;
    }
    
    .btn::after {
      bottom: -6px;
      border-top-width: 0px;
    }
    
    .btn:active,
    .btn:focus {
      outline: none;
    }
    
    .btn:active::before,
    .btn:active::after {
      right: -3px;
      left: -3px;
    }
    
    .btn:active::before {
      top: -3px;
    }
    
    .btn:active::after {
      bottom: -3px;
    }
  }

  @media only screen and (max-device-width: 915px) and (orientation: landscape) {
    #btn-group {
      margin-top: 20px;
      margin-bottom: 20px;
      justify-content: center;
      width: 100%;
    }
    .btn {
        max-width: screen;
        padding: 0px;  
    }
    .btn__content{
      font-size: 1.25vw;
    }
    .btn::before,
    .btn::after {
      content: '';
      display: block;
      position: absolute;
      right: -6px; left: -6px;
      height: calc(50% - 5px);
      border: 1px solid var(--border-color);
      transition: all .15s ease;
    }
    
    .btn::before {
      top: -6px;
      border-bottom-width: 0px;
    }
    
    .btn::after {
      bottom: -6px;
      border-top-width: 0px;
    }
    
    .btn:active,
    .btn:focus {
      outline: none;
    }
    
    .btn:active::before,
    .btn:active::after {
      right: -3px;
      left: -3px;
    }
    
    .btn:active::before {
      top: -3px;
    }
    
    .btn:active::after {
      bottom: -3px;
    }
  }