@import '~antd/dist/antd.css';
#players {
    width: 100%;
    margin: 3% 0;
}

.players-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
}

.background-enhancer {
    background-size: 100% !important;
}

.spin-icon-enhancer {
    font-size: 70px;
}

.index-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.player-card {
    height: auto;
    width: 200px;
    margin: 1% 2%;
    box-shadow: 0px 0px 10px white;
    transition: 0.2s;
}

.player-card img{
    height: auto;
    width: 150px;
    margin: 2% 0%;
    box-shadow: 0px 0px 10px white;
    transition: 0.2s;
}

.player-card text {
    font-weight: bold;
    font-size: 15px;

}

.player-card p {
    margin-bottom: 0px;
}

.player-card:hover {
    box-shadow: 0px 0px 30px white;
    transform: scale(1.05);
    transition: 0.2s;
}

.player-card .ant-card-meta-title {
    font-family: 'Montserrat Bold';
}

.player-card .ant-card-meta-description {
    text-align: left;
}

@media only screen and (max-device-width: 900px) and (orientation: landscape) {
    .background-enhancer {
        background-size: 300% !important;
        background-repeat: repeat !important;
    }
    .player-card {
        margin: 2% 2%;
    }
}

@media only screen and (max-device-width: 768px) {
    .background-enhancer {
        background-size: 300% !important;
        background-repeat: repeat !important;
    }
    .player-card {
        margin: 3% 2%;
    }
}