@import '~antd/dist/antd.css';
#signup {
    background-color: white;
    border-radius: 20px;
    padding: 20px 20px;
    text-align: center;
    box-shadow: 0px 0px 7px black;
}

#regulation-icon {
    color: rgb(179, 11, 0);
    transition: 0.2s;
}

#regulation-icon:hover {
    color: red;
    transition: 0.2s;
}

#file-name {
    margin-top: 3%;
    color: black;
    transition: 0.2s;
}

#file-name:hover {
    color: rgb(141, 141, 141);
    transition: 0.2s;
}

#platform-div {
    display: inline-block;
}

.platform-btn, .platform-btn:hover, .platform-btn:focus {
    height: 120px;
    width: 120px;
    margin: 0px 20px;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    box-shadow: none;
}

#pc-btn {
    float: left;
    background-image: url("../img/computer.png");
}

#pc-btn:hover {
    background-image: url("../img/computer_color.png");
}

#ps-btn {
    float: right;
    background-image: url("../img/playstation.png");
}

#ps-btn:hover {
    background-image: url("../img/playstation_color.png");
}

#declaration-text {
    text-align: justify;
}

@media only screen and (min-width: 1200px) {
    #signup {
        width: 34%;
    }
    #regulation-icon {
        font-size: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    #signup {
        width: 48%;
    }
    #regulation-icon {
        font-size: 60px;
    }
}

/* telemovel landscape */

@media only screen and (max-device-width: 900px) and (orientation: landscape) {
    #signup {
        margin: 4px 0px;
        width: 50vw;
    }
}

/* telemovel */

@media only screen and (max-width: 768px) {
    #signup {
        width: 95vw;
    }
    #regulation-icon {
        font-size: 36px;
    }
}