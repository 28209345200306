.collection-create-form_last-form-item {
    margin-bottom: 0;
}

.image {
    height: 100px;
    width: 100px;
}

.centered-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editions-lists {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.editions-lists .one-fifth-width {
    margin: 0 15px;
}

.editions-lists .regular-text:hover {
    background-color: rgb(224, 224, 224);
}

.max-width {
    width: 100%;
}

.one-fifth-width {
    width: 20%;
}

.active-edition {
    background-color: rgb(169, 233, 169);
}

.active-edition:hover {
    background-color: rgb(123, 255, 123) !important;
}

.disclaimer-text {
    margin-top: 10px;
}

.red-text {
    color: red;
}