@import '~antd/dist/antd.css';
#schedules {
    margin: 2% 0;
}

#container {
    max-width: 100vw;
    background-color: white;
    border-radius: 20px;
    padding: 20px 20px;
    box-shadow: 0px 0px 7px black;
    min-width: 50vw;
}

#container #match {
    max-width: 100%;
}

#match {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 10px 10px;
    border: 1px solid #aaaaaa;
    box-shadow: 0px 0px 7px black;
    margin: 10px 0;
    transition: 0.1s;
}

#match:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 15px black;
    transition: 0.1s;
}

#match:hover span.ball-span > svg {
    color: #d83b0b;
    transition: 0.9s;
    animation: spin 0.9s ease-in-out;
}

#match span {
    display: flex;
    align-items: center;
}

.time-span {
    font-size: 14px;
    width: 80px;
}

.time-span svg {
    margin-right: 5px;
}

.players-span {
    display: flex;
    justify-content: center !important;
    width: 300px;
    margin: 0 10px;
}

.players-span svg {
    margin: 0px 10px;
}

.ball-span {
    width: 40px;
}

.help-span {
    font-size: 14px;
    width: 80px;
}

#game-date:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 15px black;
    transition: 0.1s;
}

.game-date {
    font-size: 20px;
    text-align: left;
    margin-bottom: 10px;
    color: #1c1c1c;
    text-shadow: 0px 0px 7px #565655;
    
    max-width: 100vw;
    background-color: white;
    border-radius: 10px;
    padding: 20px 20px;
    box-shadow: 0px 0px 7px black;
    min-width: 50vw;
}

.game-info {
    font-size: 14px;
    color: #1c1c1c;
    text-shadow: 0px 0px 0px #ffffff;
    max-width: 100vw;
    background-color: white;
    min-width: 50vw;
    text-align: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-device-width: 915px) {
    #match {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
        
    }

    .game-date {
        text-align: center;
    }

}