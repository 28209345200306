@import '~antd/dist/antd.css';

:root {
    --shadow-color: #ffffff;
    --shadow-color-light: #747474;
    --shadow-color-hover: #dbdbdb;
    --shadow-color-light-hover: #dbdbdb;
}

.layout {
    min-height: 100vh;
}

#drop-btn {
    margin-top: 30px;
}
#signup-affix {
    position: absolute;
    top: 1%;
    right: 1%;
}

#signup-btn {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
    float: right;
    align-items: right;
    background-color: #b6d3a8;
    border: none;
    box-shadow: 0px 0px 15px #60ad39;
    z-index: 999999;
}

#signup-btn:hover,
#signup-btn:focus {
    box-shadow: 0px 0px 30px #86e955;
    background-color: #c4ffa7;
    color: #2e2e2e;
    transition: 0.2s;
}

.header-common {
    background-image: url("./assets/img/cabecalho.png");
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid #ffffff4d;
    z-index: 999999;
}

.header-element {
    display: flex;
    align-items: center;
}

#league-logo {
    height: 3.3rem;
    margin-right: 1rem;
}

#league-logo:hover {
    opacity: 0.75;
}

#league-name {
    font-size: 30px;
    font-family: 'Montserrat Bold';
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #dfdfdf;
    letter-spacing: 3px;
    text-shadow: 0 0 3px var(--shadow-color-light), 0 0 10px var(--shadow-color-light), 0 0 20px var(--shadow-color-light), 0 0 30px var(--shadow-color);
    transition: 0.3s;
}

#league-name:hover {
    transition: 0.3s;
    color: #ffffff;
    text-shadow: 0 0 3px var(--shadow-color-light-hover), 0 0 10px var(--shadow-color-light-hover), 0 0 20px var(--shadow-color-light-hover), 0 0 30px var(--shadow-color-hover);
}

.layout-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: url('./assets/img/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.display{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.rc-footer {
    z-index: 8;
    box-shadow: 0px 0px 15px rgb(143, 143, 143);
}

.footer-title {
    color: #000000;
    font-size: 200%;
    font-family: 'Montserrat Bold';
    text-align: center;
}

.divider {
    background-color: #000000;
    width: 100%;
    opacity: 0.2;
}

#email-link:link {
    color: rgb(187, 62, 23);
}

#email-link:hover {
    color: rgb(255, 100, 52);
    transition: 0.2s;
}

.footer-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}

.aeisep-logo {
    height: 120px;
}

.aeisep-logo:hover {
    opacity: 0.7
}

.centered-row {
    text-align: center;
}

.patrocinios{
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
}

.sponsor{
    margin: 0rem 1rem;
}

#fb-btn:hover,
#fb-btn:focus {
    border-color: #4267B2;
    color: #4267B2;
    transition: 0.15s;
}

#yt-btn:hover,
#yt-btn:focus {
    border-color: #FF0000;
    color: #FF0000;
    transition: 0.15s;
}

#li-btn:hover,
#li-btn:focus {
    border-color: #2867b2;
    color: #2867b2;
    transition: 0.15s;
}

#ig-btn:hover,
#ig-btn:focus {
    border-color: #E1306C;
    color: #E1306C;
    transition: 0.15s;
}

.sponsor-image:hover {
    opacity: 0.7;
}

#sponsor-pporto {
    width: 6rem;
}

#sponsor-moche {
    width: 4rem;
}

#sponsor-ipdj {
    width: 4rem;
}

.copyright-footer {
    background-color: #b6d3a8;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* COMMON */

.hidden-message {
    visibility: hidden;
}

.bold-text {
    font-family: 'Montserrat Bold';
}

.semibold-text {
    font-family: 'Montserrat Semi-Bold';
}

.regular-text {
    font-family: 'Montserrat Regular';
}

.title {
    font-size: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
}

/* override antd table properties */

.ant-table {
    box-shadow: 0px 0px 7px black;
}

.ant-table-container {
    border: 1px solid #4e4e4e !important;
}

.ant-table-thead>tr>th {
    border-bottom: 2px solid black;
}

/* every normal (not 1 2 3 if highlighted) */

.ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: #6e6b6b !important;
    color:white;
}

.table-normal:nth-child(even) {
    background-color: #ffffff;
}

.table-normal:nth-child(odd) {
    background-color: #e7e7e7;
}

table th,
table td {
    padding: 6px 6px !important;
}

/* 1 2 3 highlighted*/

.ant-table-tbody>tr.table-highlighted[data-row-key="1"]:hover>td {
    background-color:  #ad2102!important;
    color:white;
}

.ant-table-tbody>tr.table-highlighted[data-row-key="2"]:hover>td {
    background-color: #ad2102 !important;
    color:white;
}

.ant-table-tbody>tr.table-highlighted[data-row-key="3"]:hover>td {
    background-color: #6e6b6b !important;
    color:white;
}

.table-highlighted:nth-child(1) {
    background-color: #e74607;
}

.table-highlighted:nth-child(2) {
    background-color: #ff7a45;
}

.table-highlighted:nth-child(3) {
    background-color: #ffffff;
}

tr.ant-table-row {
    height: 6vh;
}

ul.ant-pagination>li,
ul.ant-pagination>li>a,
ul.ant-pagination>li>button {
    transition: 0.2s;
}

ul.ant-pagination>li.ant-pagination-item-active,
ul.ant-pagination>li:hover,
ul.ant-pagination>li:focus,
ul.ant-pagination>li:hover[aria-disabled="false"]>button,
ul.ant-pagination>li:focus[aria-disabled="false"]>button {
    border-color: #fa541c !important;
    transition: 0.2s;
}

ul.ant-pagination>li.ant-pagination-item-active>a,
ul.ant-pagination>li:hover>a,
ul.ant-pagination>li:focus>a,
ul.ant-pagination>li:hover[aria-disabled="false"]>button,
ul.ant-pagination>li:focus[aria-disabled="false"]>button {
    color: #fa541c !important;
    transition: 0.2s;
}

.spin-icon {
    color: white;
    filter: drop-shadow(0px 0px 5px black);
}

.btn-group {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    
}

/* button */

.btn-group>button {
    height: 60px;
    width: 60px;
    margin: 0 4px;
    border: none;
    box-shadow: 0px 0px 10px black;
}

.btn-group>button:hover {
    border: none;
}

/* icon inside button */

.btn-group>button>svg {
    display: block;
    margin: auto;
}

.sm-pc-btn:hover,
.sm-pc-btn:focus {
    color: #ed2024;
    box-shadow: 0px 0px 15px #ed2024;
}

.sm-ps-btn:hover,
.sm-ps-btn:focus {
    color: #ed2024;
    box-shadow: 0px 0px 15px #ed2024;
}

.centered-btn-group {
    justify-content: center !important;
    margin-bottom: 20px !important;
}

.centered-btn-group>button {
    margin: 0 15px !important;
}

/* computer */

@media only screen and (min-device-width: 768px) {
    .header-common c{
        visibility: hidden;
        display: none;
    }

    .rc-footer-container {
        max-width: unset;
        padding: 0px;
        width: 100%;
        margin: auto;
    }

    .rc-footer-columns {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: center;
        display: flex;
    }

    .rc-footer-column {
        width: 20%;
    }

    .centered-row>.social-network-icon {
        display: inline-block;
        width: 15%;
    }

    .copyright-footer {
        height: auto;
    }
}

/* mobile landscape */

@media only screen and (max-device-width: 900px) and (orientation: landscape) {
    .layout-content b{
        visibility: hidden;
        display: none;
    }
    .rc-footer-column {
        width: 30%;
    }

    .layout-content {
        min-height: 600px;
        background-size: auto;
    }

    .centered-row>.social-network-icon {
        display: inline-block;
        width: 20%;
    }

    .copyright-footer {
        height: 10vh;
    }
}

/* mobile portrait */

@media only screen and (max-device-width: 768px) {
    .layout-content b{
        visibility: hidden;
        display: none;
    }
    
    #header {
        height: 52px;
        padding-left: 2vw;
    }

    #league-logo {
        height: 3.3rem;
    }

    #league-name {
        font-size: 20px;
    }

    .layout-content {
        background-image: url("./assets/img/back_phone.png");
        background-size: cover;
        flex-direction: column;
        min-height: 620px;
    }

    .display{
        height: 100%;
    }

    .rc-footer-container {
        max-width: 100%;
        padding: 0px;
        width: 100%;
    }

    .rc-footer-columns {
        display: flex;
        flex-direction: column;
    }

    .rc-footer-column {
        margin: 20px 0px;
        width: 100%;
    }

    .centered-row>.social-network-icon {
        display: inline-block;
        width: 15%;
    }

    .patrocinios{
        flex-direction: column;
        min-height: 480px;
    }

    .sponsor{
        margin: 1rem 1rem;
    }

    .copyright-footer {
        height: auto;
    }

    .header-font {
        font-size: 90%;
    }

    table th,
    table td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}